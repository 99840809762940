import React, { useState, FC, useEffect } from 'react';
import Button from "src/components/Button";
import { ReactComponent as FiChevronRight } from "@assets/icons/chevron-right.svg";
import { ReactComponent as FiChevronDown } from "@assets/icons/chevron-down.svg";
import { ReactComponent as StringIcon } from "@assets/icons/string-icon.svg";
import { ReactComponent as NumberIcon } from "@assets/icons/number-icon.svg";
import { ReactComponent as BooleanIcon } from "@assets/icons/boolean-icon.svg";
import jsonPlaceholder from "@assets/icons/jsonPlaceHolder.png";
import { JsonNodeProps, JsonViewerProps } from './JsonInput.types';
import { classNames } from 'src/utils/utils';


const getIcon = (dataType: string) => {
  switch (dataType) {
    case "text":
      return <StringIcon />;
    case "number":
      return <NumberIcon />;
    case "boolean":
      return <BooleanIcon />;
    default:
      return null;
  }
};


const JsonNode: FC<JsonNodeProps> = ({ node, isNullableEnabled, readOnly, extraSpace = true, expandAll=false }) => {
  const hasChildren = node.children && node.children.length > 0;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(Boolean(hasChildren));

  useEffect(() => {
    // if(expandAll){
      setIsCollapsed(!expandAll);
    // }
  },[expandAll])


  return (
    <div className={classNames(extraSpace?'pl-4':'')}>
      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-2">
            {hasChildren && (
                <button
                    className="text-blue-500 hover:underline"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    {isCollapsed ? <FiChevronRight /> : <FiChevronDown />}
                </button>
            )}

            { (!hasChildren && isNullableEnabled && !readOnly) && <input id={`${node.id}_nullable`}  type="checkbox" />}
            {!hasChildren && getIcon(node.dataType)}
            <span className="font-semibold">{node.name}</span>
            {!hasChildren &&<span className="text-gray-500 capitalize">{node.dataType}</span>}
            {node?.isArray &&<span className="text-gray-500 capitalize bg-neutral-25 px-2">Array</span>}
            {hasChildren && (
                <span className="text-gray-500 capitalize">{node.dataType}</span>
            )}
        </div>
        {hasChildren &&<span className="text-gray-500">{`{${node?.children?.length}}`}</span>}
      </div>
      {/* {
        (!hasChildren && !readOnly) && 
            <Input
                defaultValue={node.defaultInput}
                id={`${node.id}_defaultInput`}
                placeholder="Enter value"
                className="max-w-[300px] h-[27px] mr-1 mt-3"
            />
      } */}
      {!isCollapsed && (
        <div className="mt-2 space-y-2">
          {hasChildren && (
            node.children?.map((child, index) => (
              <JsonNode key={index} node={child} isNullableEnabled={isNullableEnabled} readOnly={readOnly}  expandAll={expandAll}/>
            ))
          )}
        </div>
      )}
    </div>
  );
};

const InputJsonTreeRender: FC<JsonViewerProps> = ({ data, isNullableEnabled, readOnly }) => {
  const [expandAll, setExpandedAll] = useState<boolean>(false);

  const handleExpandClick = () => {
    setExpandedAll(!expandAll);
  }

  return (
        <div className="h-full max pl-2 pt-2 gap-4 flex flex-col border-l">
            <div className='flex justify-between items-center mx-2'>
              <div className="text-sm text-neutral-black font-medium">Tree</div>
              {data?
              <Button
                variant="outline"
                className="ml-auto w-full justify-center !w-[70px] "
                onClick={handleExpandClick}
              >
                {expandAll?'Collapse':'Expand'}
              </Button>
              :null}
            </div>
            <div className="h-full max-h-[500px] w-full flex overflow-hidden">
              {!data?( 
                  <div
                      className='h-3/4 w-full flex justify-center items-center'
                      style={{
                          pointerEvents: 'none', // Make the image unclickable
                      }}
                      >
                      <div className="flex flex-col justify-center items-center max-w-[290px] gap-4">
                          <img
                              src={jsonPlaceholder} // Replace with your image URL
                              alt="placeholder"
                          />
                          <span className="font-b2 text-neutral-500 text-center">
                              Paste your JSON on the left and click 'Transform' to see data structure here.
                          </span>
                      </div>
                  </div>
                )
              : 
                <div className="h-full max-h-[600px] w-full flex overflow-scroll show-scrollbar"><JsonNode expandAll={expandAll} node={data} isNullableEnabled={isNullableEnabled} readOnly={readOnly} extraSpace={false}/></div>}
            </div>
        </div>
  );
};


export default React.memo(InputJsonTreeRender);