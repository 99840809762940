import { InputError, InputParamDataEnum, WorkflowCustomInputType } from "./InputParameters.types";

function findDuplicateIndexes(arr: string[]): number[] {
  const map = new Map<string, number>();
  const duplicateIndexes: number[] = [];

  arr.forEach((str, index) => {
    if (map.has(str)) {
      duplicateIndexes.push(index);
    } else {
      map.set(str, index);
    }
  });
  return duplicateIndexes;
}
export const isDuplicate = (newErrorList:InputError, inputParamList:Array<WorkflowCustomInputType>) => {
    const names = inputParamList.map((item) => item.name);
    const duplicateIndexes = findDuplicateIndexes(names);
    if(duplicateIndexes.length){
      duplicateIndexes.forEach((index) => {
        if(newErrorList?.[index]?.name){
          newErrorList[index].name = `Duplicate name ${inputParamList?.[index]?.name}`;
        } else {
          newErrorList[index] = {
            ...newErrorList[index],
            name: `Duplicate name ${inputParamList?.[index]?.name}`,
          };
        }
      });
    }
    return newErrorList;

}

export const validateInputField = (newErrorList:InputError, inputParamList:Array<WorkflowCustomInputType>,isNullableEnabled:boolean, checkConfig:boolean = true) => {
    inputParamList.forEach((item, index) => {
        const input = item?.defaultInput ?? "";
        if (
            inputParamList?.[index]?.defaultInput &&
            inputParamList?.[index]?.defaultInput.length > 0 &&
            isNullableEnabled &&
            !inputParamList?.[index]?.isNullable
        ) {
            newErrorList[index] = {
              ...newErrorList[index],
              isNullable: "Input should be nullable when there is a default value",
            };
        } else if(item.dataType===InputParamDataEnum.Number && inputParamList?.[index]?.defaultInput?.length && !/\d/.test(input) && input !== "null"){
            if(newErrorList?.[index]?.dataType){
              newErrorList[index].dataType = "Default value should be a number or null";
            } else {
              newErrorList[index] = {
                ...newErrorList[index],
                dataType: "Default value should be a number or null",
              };
            }
        } else if(item.dataType===InputParamDataEnum.Boolean && inputParamList?.[index]?.defaultInput?.length && !(["true", "false", "null"].includes(input))){
          if(newErrorList?.[index]?.dataType){
            newErrorList[index].dataType = "Default value should be a number or null";
          } else {
            newErrorList[index] = {
              ...newErrorList[index],
              dataType: "Default value should be one of true, false or null",
            };
          }
        }  else if(!item?.name?.trim()?.length){
          if(newErrorList?.[index]?.name){
            newErrorList[index].name = "Name is required";
          } else{
            newErrorList[index] = {
              ...newErrorList[index],
              name: "Name is required"
            };
          }
        } else if(item.dataType===InputParamDataEnum.Object && item?.id.includes('dummy') && checkConfig){
          if(newErrorList?.[index]?.defaultInput){
            newErrorList[index].defaultInput = "Please configure the object";
          } else {
            newErrorList[index] = {
              ...newErrorList[index],
              defaultInput: "Please configure the object",
            };
          }
        }
                            
    });

    return newErrorList;

}