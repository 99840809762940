import React, { useRef, useEffect, ChangeEvent, useState } from "react";

const JSONEditor = ({ setJsonInput, jsonInput }: { setJsonInput: (newContent: string) => void, jsonInput: string }) => {
    const editorRef = useRef<HTMLTextAreaElement>(null);
    const lineNumbersRef = useRef<HTMLDivElement>(null);
    const [lineNumbers, setLineNumbers] = useState<string[]>(["1"]);

    const updateLineNumbers = () => {
        const lines = jsonInput.split("\n").length;
        setLineNumbers(Array.from({ length: lines }, (_, i) => (i + 1).toString()));
    };

    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setJsonInput(e.target.value);
        updateLineNumbers();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Tab") {
            e.preventDefault();
            const target = e.target;
            const start = (target as HTMLTextAreaElement).selectionStart;
            const end = (target as HTMLTextAreaElement).selectionEnd;
            const newValue = jsonInput.substring(0, start) + "  " + jsonInput.substring(end);
            setJsonInput(newValue);
            setTimeout(() => {
                (target as HTMLTextAreaElement).selectionStart = (target as HTMLTextAreaElement).selectionEnd = start + 2;
            }, 0);
            updateLineNumbers();
        }
    };

    useEffect(() => {
        updateLineNumbers();
    }, [jsonInput]);

    // Sync scroll between line numbers and editor
    const handleScroll = () => {
        if (lineNumbersRef.current && editorRef.current) {
            lineNumbersRef.current.scrollTop = editorRef.current.scrollTop;
        }
    };

    return (
        <div className="h-full max pl-2 pt-2 gap-4 flex flex-col">
            <div className="text-sm text-neutral-black font-medium">Code</div>
            <div className="h-full max-h-[500px] w-full flex overflow-hidden show-scrollbar">
                {/* Line Numbers */}
                <div
                    ref={lineNumbersRef}
                    className="h-full bg-gray-100 pr-2 text-right text-gray-600 font-mono text-sm select-none overflow-y-hidden"
                >
                    {lineNumbers.map((lineNumber) => (
                        <div key={lineNumber} className="leading-6">{lineNumber}</div>
                    ))}
                </div>
                {/* JSON Editor */}
                <div className="h-full w-full overflow-auto">
                    <textarea
                        ref={editorRef}
                        autoFocus
                        value={jsonInput}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onScroll={handleScroll}
                        className="w-full h-full font-mono text-sm no-outline border-none resize-none bg-neutral-0"
                        aria-label="JSON input"
                        spellCheck="false"
                        placeholder="// Paste your JSON and click ’Transform’ to see data structure here //"
                        style={{
                            paddingLeft: "0.3rem",
                            whiteSpace: "pre", // Allows horizontal scrolling
                            overflowX: "auto", // Enables horizontal scrolling
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(JSONEditor);
